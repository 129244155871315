import { defineStore } from 'pinia'
import Cookies from 'js-cookie'

import { OvertaxStorage } from '@/utils/overtax'
import { logout, OverTaxUser, GetUserResponse, getRecommendCode } from '@/api/user'
import { ProcessStatus } from '@/utils/process'

interface UserState {
  email: string;
  name: string;
  id: string;
  ouid: string;
  username: string; // to-be removed
  social_auth?: Array<{
    // 카카오계정의 전화번호
    // 국내 번호인 경우 +82 00-0000-0000 형식
    // 해외 번호인 경우 자릿수, 붙임표(-) 유무나 위치가 다를 수 있음
    // (참고: libphonenumber)
    phone_number: string;
    // 출생 연도(YYYY 형식)
    birthyear: string;
    // 생일(MMDD 형식)
    birthday: string;
    name: string;
  }>,
  overtax_user?: null | OverTaxUser;
  ref_code?: string;
}

const localStorageKey = 'oui'

const initState: UserState = {
  email: '',
  name: '',
  id: '',
  ouid: '',
  username: '',
  social_auth: undefined,
  overtax_user: null,
}

export const getInitState = (): UserState => {
  const state = OvertaxStorage.get(localStorageKey) as UserState
  return state ? state : initState
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => {
    return getInitState()
  },
  actions: {
    async logout() {
      await logout().then(() => {
        this.setInitState()
      })
    },
    setInitState () {
      OvertaxStorage.init()
      this.$state = getInitState()
      Cookies.remove('sessionid')
    },
    setState(res: GetUserResponse) {
      const newState = {
        email: res.email,
        name: `${res.last_name}${res.first_name}`,
        id: res.id.toString(),
        ouid: res.ouid,
        username: res.username,
        social_auth: res.social_auth,
        overtax_user: res.overtax_user,
      }
      OvertaxStorage.set(localStorageKey, newState)
      this.$state = newState
    },
    updateStatusAfterContract() {
      if (this.$state.overtax_user) {
        this.$state.overtax_user.process[0].status = ProcessStatus.IN_SURVEY
      }
    },
    getRefundAmount() {
      const refundSum = this.$state.overtax_user?.process[0]?.refund_amount_sum
      if (refundSum && refundSum > 0) {
        return refundSum
      }
      
      const refundData = this.$state.overtax_user?.process[0]?.refund_amount
      
      if (refundData && refundData.length > 0) {
        return refundData.reduce((prev, curr) => prev + Math.floor(curr.amount * -1), 0)
      } else {
        return 0
      }
    },
    async getRefCode() {
      if(!this.$state.ref_code) {
        try {
          const { ref_code } = await getRecommendCode()
          this.$state.ref_code = ref_code
        } catch (e) {
          this.$state.ref_code = ''
        }
      }
      return this.$state.ref_code
    },
  },
});
