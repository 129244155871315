import { LocationQuery } from 'vue-router'

export const vueRouteToString = (path: string, query: LocationQuery ) => {
  return `${path}?${Object.keys(query).map((item) => `${item}=${query[item]}`).join('&')}`
}

export const stringToVueRoute = (refer: string) => {
  const toPath = refer.split('?')[0]
  const toQuery = refer.split('?')[1]
  const toQueryObj: { [key: string]: string } = {}
  if (toQuery) {
    const queryList = toQuery.split('&')
    queryList.forEach((item) => {
      const key = item.split('=')[0]
      const value = item.split('=')[1]
      toQueryObj[key] = value
    })
  }

  return {
    path: toPath,
    query: toQueryObj,
  }
}
