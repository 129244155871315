import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { createHead } from '@unhead/vue'

import App from './App.vue'
import router from './router'

const app = createApp(App)
const pinia = createPinia()
const head = createHead()

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.1,
  });
}

app.use(head).use(pinia).use(router).mount('#app')

// Kakao JS initialization started
const isInitialized = window.Kakao?.isInitialized()
if (!isInitialized) {
  // eslint-disable-next-line
  console.error("Kakao JS initialization failed.")
}
// End of Kakao JS initialization

// eslint-disable-next-line
console.log(`
%c
  __   _  _  ____  ____  ____  __   _  _ 
 /  \\ / )( \\(  __)(  _ \\(_  _)/ _\\ ( \\/ )
(  O )\\ \\/ / ) _)  )   /  )( /    \\ )  ( 
 \\__/  \\__/ (____)(__\\_) (__)\\_/\\_/(_/\\_)

 https://www.heumlabs.io/
`, 'background: transparent; color: #5061FA')

