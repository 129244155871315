import axios from 'axios'
import * as Sentry from '@sentry/vue'

import { errorStatus } from '@/constants/errorStatus'
import { getDefaultErrors } from '@/constants/errorStatus'
import { isMedistream } from '@/utils/medistream'
import router from '@/router'

const getBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_API_URL
}

const getCorpBaseUrl = () => {
  return process.env.NODE_ENV === 'development' ? '/corp' : process.env.VUE_APP_CORP_API_URL
}

const instance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'X-SERVICE-NAME': isMedistream() ? 'employment_incentive' : 'overtax',
  },
})

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  const status = error?.response?.status || ''
  const scope = new Sentry.Scope()
  scope.setExtra('err object', error.toJSON())
  scope.setExtra('err response', JSON.stringify(error.response))
  if (status === errorStatus.PARAMETER_ERROR || status === errorStatus.FORBIDDEN ||  status === errorStatus.TIMEOUT) {
    const errors = error.response.data.errors
    if (!errors || !Array.isArray(errors)) {
      Sentry.captureException(new Error('errors is not defined'), scope)
      return Promise.reject(getDefaultErrors('errors is not defined'));
    } else {
      return Promise.reject(error.response.data.errors)
    }
  }

  // if (status === errorStatus.NOT_FOUND) {
  //   router.push({ name: '404' })
  // }

  if (status === errorStatus.SERVER_ERROR) {
    router.push({ name: '500' })
  }

  Sentry.captureException(new Error('server error'), scope)
  return Promise.reject(getDefaultErrors('server error'));
})

const infoTechInstance = axios.create({ 
  baseURL: 'https://127.0.0.1:16566/',
})

const surveyInstance = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: true,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
})

const noTokenInstance = axios.create({
  baseURL: getBaseUrl(),
})

noTokenInstance.interceptors.response.use((response) => {
  return response
}, (error) => {
  Sentry.captureException(error)
})

const corpInstance = axios.create({ 
  baseURL: getCorpBaseUrl(),
})

export { 
  instance as axios,
  infoTechInstance as infoTechAxios,
  surveyInstance as surveyAxios,
  corpInstance as corpAxios,
  noTokenInstance as noTokenAxios,
}
