const OvertaxStorage = {
  init(key?: string) {
    if (key) localStorage.removeItem(key)
    else localStorage.clear()
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  },
  get(key: string) {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : undefined
  },
}

export { OvertaxStorage }
