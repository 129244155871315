export const enum ProcessStatus {
  // 초기 상태 - null
  // 환급금 계산중
  ESTIMATE_REFUND = 'ESTIMATE_REFUND', // 예상환급액 진단중
  ESTIMATION_IN_REVIEW = 'ESTIMATION_IN_REVIEW', // 예상 환급액 검토대기
  // 환급금 노출 이후
  ESTIMATION_WAITING_FROM_CUSTOMER = 'ESTIMATION_WAITING_FROM_CUSTOMER', // 예상 환급액 확인대기
  WAITING_CONTRACT = 'WAITING_CONTRACT', // 환급신청 대기 // TODO: deleted 
  IN_CONTRACT = 'IN_CONTRACT', // 계약대기
  PREPARING_BANK_ACCOUNT = 'PREPARING_BANK_ACCOUNT', // 환급계좌 입력
  // 계약 완료 이후
  IN_ASSIGNING_STAFF = 'IN_ASSIGNING_STAFF', // 담당자 연결대기 // TODO: deleted 
  IN_SURVEY = 'IN_SURVEY', // 설문 작성대기
  CALCULATING_REFUND = 'CALCULATING_REFUND', // 확정 진단중
  CREATING_REPORT = 'CREATING_REPORT', // 접수 준비중 (신고서 생성)
  COMPLETE_REPORT = 'COMPLETE_REPORT', // 환급 접수 완료
  SUBMITTED = 'SUBMITTED', // 국세청 환급대기
  COMPLETE_REFUND = 'COMPLETE_REFUND', // 환급완료
  DONE = 'DONE', // 종료 (서류 전달)
}

interface ProcessDataProps {
  status: ProcessStatus;
  desc: string;
}

export const ProcessData: ProcessDataProps [] = [
  {
    status: ProcessStatus.ESTIMATE_REFUND,
    desc: '예상환급액 진단중',
  },
  {
    status: ProcessStatus.ESTIMATION_IN_REVIEW,
    desc: '예상 환급액 검토대기',
  },
  {
    status: ProcessStatus.ESTIMATION_WAITING_FROM_CUSTOMER,
    desc: '예상 환급액 확인대기',
  },
  {
    status: ProcessStatus.WAITING_CONTRACT,
    desc: '환급신청 대기',
  },
  {
    status: ProcessStatus.IN_CONTRACT,
    desc: '계약대기',
  },
  {
    status: ProcessStatus.PREPARING_BANK_ACCOUNT,
    desc: '환급계좌 입력',
  },
  {
    status: ProcessStatus.IN_ASSIGNING_STAFF,
    desc: '담당자 연결대기',
  },
  {
    status: ProcessStatus.IN_SURVEY,
    desc: '설문 작성대기',
  },
  {
    status: ProcessStatus.CALCULATING_REFUND,
    desc: '확정 진단중',
  },
  {
    status: ProcessStatus.CREATING_REPORT,
    desc: '접수 준비중',
  },
  {
    status: ProcessStatus.COMPLETE_REPORT,
    desc: '접수 완료',
  },
  {
    status: ProcessStatus.SUBMITTED,
    desc: '세무서 검토 중',
  },
  {
    status: ProcessStatus.COMPLETE_REFUND,
    desc: '환급완료',
  },
  {
    status: ProcessStatus.DONE,
    desc: '종료',
  },
]

export const getStatusText = (status: ProcessStatus) => {
  return ProcessData.find((item) => item.status === status)?.desc
}

export const isAfterContract = (targetStatus: ProcessStatus | undefined) => {
  let result = false
  switch (targetStatus) {
  case ProcessStatus.ESTIMATE_REFUND:
  case ProcessStatus.ESTIMATION_IN_REVIEW:
  case ProcessStatus.ESTIMATION_WAITING_FROM_CUSTOMER:
  case ProcessStatus.WAITING_CONTRACT:
  case ProcessStatus.IN_CONTRACT:
  case ProcessStatus.PREPARING_BANK_ACCOUNT:
    result = false
    break
  case ProcessStatus.IN_ASSIGNING_STAFF:
  case ProcessStatus.IN_SURVEY:
  case ProcessStatus.CALCULATING_REFUND:
  case ProcessStatus.CREATING_REPORT:
  case ProcessStatus.COMPLETE_REPORT:
  case ProcessStatus.SUBMITTED:
  case ProcessStatus.COMPLETE_REFUND:
  case ProcessStatus.DONE:
    result = true
    break
  default:
    result = false
  }
  return result
}

export const isAfterCert = (targetStatus: ProcessStatus | undefined) => {
  let result = false
  switch (targetStatus) {
  case ProcessStatus.ESTIMATE_REFUND:
  case ProcessStatus.ESTIMATION_IN_REVIEW:
    result = false
    break
  case ProcessStatus.ESTIMATION_WAITING_FROM_CUSTOMER:
  case ProcessStatus.WAITING_CONTRACT:
  case ProcessStatus.IN_CONTRACT:
  case ProcessStatus.PREPARING_BANK_ACCOUNT:
  case ProcessStatus.IN_ASSIGNING_STAFF:
  case ProcessStatus.IN_SURVEY:
  case ProcessStatus.CALCULATING_REFUND:
  case ProcessStatus.CREATING_REPORT:
  case ProcessStatus.COMPLETE_REPORT:
  case ProcessStatus.SUBMITTED:
  case ProcessStatus.COMPLETE_REFUND:
  case ProcessStatus.DONE:
    result = true
    break
  default:
    result = false
  }
  return result
}

export const isAfterCalculation = (targetStatus: ProcessStatus | undefined) => {
  let result = false
  switch (targetStatus) {
  case ProcessStatus.ESTIMATE_REFUND:
  case ProcessStatus.ESTIMATION_IN_REVIEW:
  case ProcessStatus.ESTIMATION_WAITING_FROM_CUSTOMER:
  case ProcessStatus.WAITING_CONTRACT:
  case ProcessStatus.IN_CONTRACT:
  case ProcessStatus.PREPARING_BANK_ACCOUNT:
  case ProcessStatus.IN_ASSIGNING_STAFF:
  case ProcessStatus.IN_SURVEY:
    result = false
    break
  case ProcessStatus.CALCULATING_REFUND:
  case ProcessStatus.CREATING_REPORT:
  case ProcessStatus.COMPLETE_REPORT:
  case ProcessStatus.SUBMITTED:
  case ProcessStatus.COMPLETE_REFUND:
  case ProcessStatus.DONE:
    result = true
    break
  default:
    result = false
  }
  return result
}
